<template>
	<div>

		<uploadExcelBtn :btnText="$t('i18nn_e2ef45e045968e11')" :openTime="openTimeUpExc" :readHttp="$urlConfig.WhPackingListExUpload+'/'+$route.query.cusUserId"
			:templateUrl="'https://hytx-oss-wh.oss-cn-hongkong.aliyuncs.com/modelv2/packImportModV2.xlsx'"
			@uploadSuccess="uploadSuccess">
		</uploadExcelBtn>
		
		<PackingWarehousingHotTable ref="HotTable" :selectDicOption="{}">
		</PackingWarehousingHotTable>

		<!-- <div style="overflow: auto; width: 100%; height: 80%;">

			<el-table :data="excelData" stripe :border="true" :max-height="$store.state.contenHeight - 250"
				style="width: 100%" size="small">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<el-table-column prop="packingNo" :label="$t('Storage.packing_list._no')">
					<template slot-scope="scope">
						{{ scope.row.packingNo }}
					</template>
				</el-table-column>

				<el-table-column prop="packageTypeName" :label="$t('Storage.packing_list.Type')"></el-table-column>
				<el-table-column prop="containerSizeName" :label="$t('i18nn_977ed6286b514ff6')"></el-table-column>
				<el-table-column prop="goodsSku" label="SKU"></el-table-column>
				<el-table-column prop="proxyCus" :label="$t('Storage.packing_list.Forwarder_guests')"></el-table-column>
				<el-table-column prop="leaveDate" :label="$t('Storage.packing_list.Departure_Date')"></el-table-column>

				<el-table-column prop="arriveDate" :label="$t('Storage.packing_list.arrive_Date')"></el-table-column>

				<el-table-column prop="leavePort" :label="$t('Storage.packing_list.Departure_port')"></el-table-column>

				<el-table-column prop="arrivePort" :label="$t('Storage.packing_list.arrive_port')"></el-table-column>
				<el-table-column prop="totalPallet" :label="$t('i18nn_b1813b05de28548d')"></el-table-column>
				<el-table-column prop="totalCTN" :label="$t('i18nn_e7b838a996147e21')"></el-table-column>
				<el-table-column prop="ctnPCS" :label="$t('i18nn_85a10d3e5ea0e103')"></el-table-column>

				<el-table-column prop="packCount" :label="$t('i18nn_bad53577db0da2d3')"></el-table-column>
				<el-table-column prop="ctnWidth" :label="'外箱尺寸/L*w*H(IN)'">
					<template slot-scope="scope">
						{{ scope.row.ctnLength }}*{{ scope.row.ctnWidth }}*{{ scope.row.ctnHeight }}
					</template>
				</el-table-column>
				<el-table-column prop="ctnWeight" :label="'整箱重量(LB)'"></el-table-column>
				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')" show-overflow-tooltip>
				</el-table-column>

			</el-table>
		</div> -->

	</div>
</template>

<script>
	import uploadExcelBtn from '@/components/WarehouseCenter2/components/uploadExcelBtn.vue';
	import PackingWarehousingHotTable from '@/components/StorageCenter/workOrder/workOrderTem/PackingWarehousingHotTable.vue';
	
	export default {

		props: {
			excelDataDto: {
				default: function() {
					return [];
				},
				type: Array
			}
		},
		components: {
			uploadExcelBtn,
			PackingWarehousingHotTable
		},
		data() {
			return {
				excelData: [],
				openTimeUpExc: "",
				loading: false,
				loading_det: false
			};
		},
		watch: {
			excelDataDto: function(newVal, oldVal) {
				console.log('excelDataDto');
				this.excelData = this.excelDataDto;
			}
		},
		activated() {
			// this.initData();
		},
		//创建时
		created() {
			// this.getPageData();
			
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.excelData = this.excelDataDto;
			// console.log("dropShippingImport",this.excelDataDto);
			this.initData();
		},
		methods: {
			initData() {
				this.$nextTick(() => {
					if(this.$refs.HotTable){
						this.$refs.HotTable.clear();
					}
				});
				
				this.openExcelDialog();
			},

			//删除数据
			// delAction(event, row, index) {
			// 	event.stopPropagation();
			// 	this.excelData.splice(index, 1);
			// },

			//上传数据回调
			uploadSuccess(data) {
				this.$nextTick(() => {
					this.excelData = data;
					this.$refs.HotTable.loadData(this.excelData);
					// this.$emit('parsingSuccess', this.excelData);
				});
			},
			
			//获取数据
			getData() {
				// this.$nextTick(() => {
					return this.$refs.HotTable.getData();
				// });
			},
			
			//打开导入弹窗
			openExcelDialog() {
				// this.dialogUploadVisible = true;
				this.openTimeUpExc = new Date().getTime();
				this.excelData = [];
				// this.clearFile();
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);
			// 	// this.loading_load = true;
			// 	// this.$http
			// 	//   .put(this.$urlConfig.HyDicQueryList, ['wh_packing_type', 'wh_size_unit'])
			// 	//   .then(({ data }) => {
			// 	//     console.log('查询数据字典，请求成功');
			// 	//     console.log(data);
			// 	//     if (200 == data.code && data.data) {
			// 	//       // this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 	//       // this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 	//       // this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 	//       this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 	//       this.selectOption.wh_packing_type = data.data['wh_packing_type'];
			// 	//     } else {
			// 	//       if (!data.msg) {
			// 	//         data.msg = this.$t("tips.submitError");
			// 	//       }
			// 	//       this.$message.warning(data.msg);
			// 	//     }
			// 	//   })
			// 	//   .catch(error => {
			// 	//     console.log(error);
			// 	//     console.log('查询数据字典接口，请求失败');
			// 	//     this.$message.error(this.$t("tips.submitRequestError"));
			// 	//   });
			// }
		}
	};
</script>

<style>
</style>
