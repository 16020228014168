export const columns_config = () => {
	return [{
			name: "订单号",
			nameEn: "Order Number",
			data: 'orderNumber',
			// editor: 'select',
			// selectOptions: []
			type: 'text',
			// type: 'dropdown',
			// source: []
			// width: 200,
		},
		{
			name: "收件人名称",
			nameEn: "Name",
			data: 'name',
			type: 'text',
			// type: 'dropdown',
			// source: []
		},
		{
			name: "公司名称",
			nameEn: "Company",
			data: 'company',
			type: 'text',
			// type: 'dropdown',
			// source: []
		},
		{
			name: "联系方式",
			nameEn: "Phone",
			data: 'phone',
			type: 'text',
		},

		{
			name: "邮件",
			nameEn: "Email",
			data: 'email',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "地址一",
			nameEn: "Street1",
			data: 'street1',
			type: 'text',
			// type: 'date', 
			// dateFormat: 'YYYY-MM-DD'
		},
		{
			name: "地址二",
			nameEn: "Street2",
			data: 'street2',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "城市",
			nameEn: "City",
			data: 'city',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "州简称二字码",
			nameEn: "State",
			data: 'state',
			type: 'text',
			// editor: 'select',
			// selectOptions: [],
			// type: 'numeric'
			// type: 'dropdown',
			// source: []
		},
		{
			name: "邮编",
			nameEn: "Postal Code",
			data: 'postalCode',
			// type: 'text'
			// type: 'dropdown',
			// source: []
			type: 'text',
		},
		{
			name: "国家",
			nameEn: "Country",
			data: 'country',
			// type: 'numeric',
			type: 'text',
		},

		{
			name: "SKU1",
			nameEn: "SKU1",
			data: 'goodsSku1',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "数量1",
			nameEn: "quantity1",
			data: 'quantity1',
			type: 'numeric',
		},
		{
			name: "SKU2",
			nameEn: "SKU2",
			data: 'goodsSku2',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "数量2",
			nameEn: "quantity2",
			data: 'quantity2',
			type: 'numeric',
		},
		{
			name: "SKU3",
			nameEn: "SKU3",
			data: 'goodsSku3',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "数量3",
			nameEn: "quantity3",
			data: 'quantity3',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "SKU4",
			nameEn: "SKU4",
			data: 'goodsSku4',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "数量4",
			nameEn: "quantity4",
			data: 'quantity4',
			type: 'numeric',
		},
		{
			name: "SKU5",
			nameEn: "SKU5",
			data: 'goodsSku5',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "数量5",
			nameEn: "quantity5",
			data: 'quantity5',
			type: 'numeric',
		},
		{
			name: "追踪单号",
			nameEn: "tracking",
			data: 'tracking',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "快递简称",
			nameEn: "trackingCompany",
			data: 'carrier',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "保险",
			nameEn: "insuranceValue",
			data: 'insuranceValue',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "是否签名",
			nameEn: "signatureServiceName",
			data: 'signatureServiceName',
			// type: 'numeric',
			// type: 'text',
			type: 'dropdown',
			source: ['YES','NO']
		},
		{
			name: "是否优先操作",
			nameEn: "Priority Level",
			data: 'isPriorityName',
			// type: 'numeric',
			// type: 'text',
			type: 'dropdown',
			source: ['YES','NO']
		},
		{
			name: "备注",
			nameEn: "Remark",
			data: 'remark',
			// type: 'text'
			type: 'text',
		},
		{
			name: "ID",
			data: 'id',
			type: 'text',
			readOnly: true
		},
	];
}


export const nestedHeadersTop_config = () => {
	return [
		[{
				label: '发货信息',
				colspan: 21
			},
			{
				label: '追踪信息(自供面单时必填)tracking Info',
				colspan: 2
			},
			{
				label: '保险和签名服务(只用于平台出单)',
				colspan: 2
			},
			'',
			'',
		]
	];
}