<template>
	<div class="mainTem">
		<!-- <div> -->
			<uploadExcelBtn
			:openTime="openTimeUpExc" 
			:readHttp="$urlConfig.WhDropShippingExUpload+'/'+$route.query.cusUserId+'?tmp='+(templateType?templateType:'')"
			:templateUrl="'https://hytx-oss-wh.oss-cn-hongkong.aliyuncs.com/modelv2/expressModelV3.xlsx'"
			:version="'V3'"
			:isUpdate="false"
			@uploadSuccess="uploadSuccess">
			</uploadExcelBtn>
			
		<!-- </div> -->
		
		<dropShippingHotTable ref="HotTable" :selectDicOption="{}">
		</dropShippingHotTable>
		
		<!-- <div style="overflow: auto; width: 100%; height: 80%;">
			<el-table :data="excelData" stripe :border="true" :max-height="$store.state.contenHeight - 250" style="width: 100%" size="small">
				<el-table-column type="expand" label="" fixed="left">
					<template slot-scope="scope">
						<div v-if="scope.row && scope.row.expressSkuList && scope.row.expressSkuList.length > 0">
							<el-table :data="scope.row.expressSkuList" :border="true" style="width: 100%" size="small">
								<el-table-column type="index" :index="$Utils.tableIndex(pagination)" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
								<el-table-column prop="goodsSku" label="SKU"></el-table-column>
								<el-table-column prop="quantity" :label="$t('Storage.DropShipping.quantity')"></el-table-column>

								<el-table-column prop="goodsSku" :label="$t('Storage.skuInfo.SKU_title')" min-width="100">
									<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">
											<div>{{ scope2.row.mySkuDto.goodsName }}</div>
											<div>{{ scope2.row.mySkuDto.goodsNameEn }}</div>
										</div>
									</template>
								</el-table-column>

								<el-table-column prop="goodsSku" :label="$t('Storage.skuInfo.SKU_pictures')" min-width="100">
									<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">
											<div>
												<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg">
													<div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
												</el-image>
											</div>
										</div>
									</template>
								</el-table-column>

								<el-table-column prop="mySkuDto" :label="$t('Storage.skuInfo.unit')" min-width="80">
									<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">{{ scope2.row.mySkuDto.sizeUnitName }}</div>
									</template>
								</el-table-column>

								<el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')" min-width="80">
									<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">{{ scope2.row.mySkuDto.goodsWeight }}</div>
									</template>
								</el-table-column>

								<el-table-column prop="mySkuDto" :label="$t('Storage.skuInfo.length_width_height')" min-width="80">
									<template slot-scope="scope2">
										<div v-if="scope2.row.mySkuDto">{{ scope2.row.mySkuDto.goodsLength }}*{{ scope2.row.mySkuDto.goodsWidth }}*{{ scope2.row.mySkuDto.goodsHeight }}</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div v-else>{{ $t('Storage.DropShipping.no_SKU') }}</div>
					</template>
				</el-table-column>

				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
				
				<el-table-column prop="exprStatusName" :label="$t('Storage.packing_list.status')">
				    <template slot-scope="scope">
				      <el-tag type="" v-if="'0' == scope.row.exprStatus">{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</el-tag>
				      <el-tag type="success" v-else-if="'10' == scope.row.exprStatus">{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</el-tag>
				      <el-tag type="warning" v-else-if="'20' == scope.row.exprStatus">{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</el-tag>
				      <el-tag type="danger" v-else-if="'30'==scope.row.exprStatus">{{scope.row.exprStatusName}}</el-tag>
							<el-tag type="success" v-else-if="'35' == scope.row.exprStatus">{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</el-tag>
							<el-tag type="warning" v-else-if="'40' == scope.row.exprStatus">{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</el-tag>
							<el-tag type="danger" v-else-if="'50'==scope.row.exprStatus">{{scope.row.exprStatusName}}</el-tag>
				      <el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</el-tag>
				    </template>
				  </el-table-column>

				<el-table-column prop="commitDate" :label="$t('Storage.DropShipping.date')"></el-table-column>

				<el-table-column prop="orderNumber" :label="$t('i18nn_abf9f4b8c93d641e')"></el-table-column>
				
				<el-table-column prop="carrier" :label="$t('i18nn_b0c50b6ff9674173')"></el-table-column>
				<el-table-column prop="tracking" :label="$t('i18nn_581bc71c027386cb')"></el-table-column>
				

				<el-table-column prop="rate" :label="$t('i18nn_d7315fb8114eb446')"></el-table-column>

				<el-table-column prop="senderAddr" :label="$t('i18nn_f6283c46ba925ab4')"></el-table-column>
				<el-table-column prop="returnAddr" :label="$t('i18nn_b4296642bcc9f428')"></el-table-column>

				<el-table-column prop="name" :label="$t('Storage.DropShipping.Addressee_name')"></el-table-column>

				<el-table-column prop="company" :label="$t('Storage.DropShipping.company')"></el-table-column>

				<el-table-column prop="phone" :label="$t('Storage.DropShipping.contact_way')"></el-table-column>

				<el-table-column prop="email" label="E-Mail"></el-table-column>
				<el-table-column prop="street1" :label="$t('Storage.DropShipping.Address_1')"></el-table-column>
				<el-table-column prop="street2" :label="$t('Storage.DropShipping.Address_2')"></el-table-column>
				<el-table-column prop="city" :label="$t('Storage.DropShipping.city')"></el-table-column>
				<el-table-column prop="state" :label="$t('Storage.DropShipping.State')"></el-table-column>
				<el-table-column prop="postalCode" :label="$t('Storage.DropShipping.postal_code')"></el-table-column>
				<el-table-column prop="country" :label="$t('Storage.DropShipping.country')"></el-table-column>
				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')" show-overflow-tooltip></el-table-column>

			</el-table>
		</div> -->
		<!-- <div class="dialog-footer">
			<el-button type="primary" @click="submitExcelForm()">{{ $t('FormMsg.Save') }}</el-button>
		</div> -->
		<!-- </el-dialog> -->

		<!--  附件上传 -->
		<!-- <whFileUpload :openTime="FileUploadOpenTime" :fileId="''" :fileKey="FileUploadKey" :relationId="fileRelationId" @success="FileUploadSuccess"></whFileUpload> -->
	</div>
</template>
<script>
import dropShippingHotTable from '@/components/StorageCenter/workOrder/workOrderTem/dropShippingHotTable.vue';

import uploadExcelBtn from '@/components/WarehouseCenter2/components/uploadExcelBtn.vue';
export default {
	props: {
	  excelDataDto: {
	    default: function() {
	      return [];
	    },
	    type: Array
	  },
		templateType:{
			default: function() {
			  return '';
			},
			type: String
		}
	},
	components: {
		uploadExcelBtn,
		dropShippingHotTable
		// WSkuInfo,
		// InWarehouseDetList,
		// whFileUpload,
		// SelAgentUser
	},
	data() {
		return {
			excelData: [],
			// excelFileName: '',
			
			openTimeUpExc:"",

			loading: false,

			loading_det: false

			
		};
	},
	watch: {
	  excelDataDto: function(newVal, oldVal) {
	    console.log('excelDataDto');
	    this.excelData = this.excelDataDto;
	  }
	},
	activated() {
		// this.initData();
		
		// this.initData();
		// this.getDicData();
	},
	//创建时
	created() {
		// this.getPageData();
		
	},
	//编译挂载前
	mounted() {
		// this.initData();
		this.initData();
	},
	methods: {
		

		initData() {
			this.$nextTick(() => {
				if(this.$refs.HotTable){
					this.$refs.HotTable.clear();
				}
				
			});
			
			this.openExcelDialog();
		},
		
		
		//上传数据回调
		uploadSuccess(data){
			// this.form.transferOrders = data.rows;
			// this.$forceUpdate();
			
			// this.excelData = data;
			
			// let formData = [];
			// try {
			// 	formData = JSON.parse(JSON.stringify(this.excelData));
			// } catch (e) {
			// 	this.$alert(this.$t('Storage.DropShipping.Parsing_failed'), this.$t('tips.tipsTitle'), {
			// 		type: 'warning'
			// 	});
			// 	return;
			// }
			
			// formData.map(item => {
			// 	// item.id = null;
			// 	item.expressSkuList.map(item2 => {
			// 		// item2.id = null;
			// 		item2.mySkuDto = null;
			// 	});
			// });
			// // this.clearFile();
			// this.$emit('parsingSuccess', formData);
			
			this.$nextTick(() => {
				this.excelData = data;
				
				console.log("uploadSuccess",this.excelData);
				
				//sku赋值。到hottable格式
				// let excelData = this.excelData;
				// this.excelData = 
				this.excelData.forEach((item,index) =>{
					if(item.expressSkuList){
						item.expressSkuList.forEach((item2,index2)=>{
							if(0==index2){
								item.goodsSku1 = item2.goodsSku;
								item.quantity1 = item2.quantity;
							} else if(1==index2){
								item.goodsSku2 = item2.goodsSku;
								item.quantity2 = item2.quantity;
							} else if(2==index2){
								item.goodsSku3 = item2.goodsSku;
								item.quantity3 = item2.quantity;
							} else if(3==index2){
								item.goodsSku4 = item2.goodsSku;
								item.quantity4 = item2.quantity;
							} else if(4==index2){
								item.goodsSku5 = item2.goodsSku;
								item.quantity5 = item2.quantity;
							}
						});
					}
				})
				// if(this.excelData.expressSkuList){
				// 	this.excelData.expressSkuList.forEach((item,index)=>{
				// 		if(0==index){
				// 			item.goodsSku1 = item.goodsSku;
				// 			item.quantity1 = item.quantity;
				// 		} else if(1==index){
				// 			item.goodsSku2 = item.goodsSku;
				// 			item.quantity2 = item.quantity;
				// 		} else if(2==index){
				// 			item.goodsSku3 = item.goodsSku;
				// 			item.quantity3 = item.quantity;
				// 		} else if(3==index){
				// 			item.goodsSku4 = item.goodsSku;
				// 			item.quantity4 = item.quantity;
				// 		} else if(4==index){
				// 			item.goodsSku5 = item.goodsSku;
				// 			item.quantity5 = item.quantity;
				// 		}
				// 	});
				// }
				
				// console.log("uploadSuccess",this.excelData);
				
				this.$refs.HotTable.loadData(this.excelData);
				// this.$emit('parsingSuccess', this.excelData);
			});
			
		},
		
		//获取数据
		getData() {
			// this.$nextTick(() => {
				let excelData = this.$refs.HotTable.getData();
				// excelData.expressSkuList.forEach((item,index)=>{
				// 	item.expressSkuList.push({
						
				// 	});
				// });
				excelData.forEach((item,index)=>{
					item.expressSkuList = [];
					if(!!item.goodsSku1){
						item.expressSkuList.push({
							goodsSku: item.goodsSku1,
							quantity: item.quantity1,
						});
					} 
					if(!!item.goodsSku2){
						item.expressSkuList.push({
							goodsSku: item.goodsSku2,
							quantity: item.quantity2,
						});
					}  
					if(!!item.goodsSku3){
						item.expressSkuList.push({
							goodsSku: item.goodsSku3,
							quantity: item.quantity3,
						});
					} 
					if(!!item.goodsSku4){
						item.expressSkuList.push({
							goodsSku: item.goodsSku4,
							quantity: item.quantity4,
						});
					} 
					if(!!item.goodsSku5){
						item.expressSkuList.push({
							goodsSku: item.goodsSku5,
							quantity: item.quantity5,
						});
					}
				})
				// excelData.expressSkuList = [];
				// if(excelData.goodsSku1){
				// 	excelData.expressSkuList.push({
				// 		goodsSku: excelData.goodsSku1,
				// 		quantity: excelData.quantity1,
				// 	});
				// } else if(excelData.goodsSku2){
				// 	excelData.expressSkuList.push({
				// 		goodsSku: excelData.goodsSku2,
				// 		quantity: excelData.quantity2,
				// 	});
				// } else if(excelData.goodsSku3){
				// 	excelData.expressSkuList.push({
				// 		goodsSku: excelData.goodsSku3,
				// 		quantity: excelData.quantity4,
				// 	});
				// } else if(excelData.goodsSku4){
				// 	excelData.expressSkuList.push({
				// 		goodsSku: excelData.goodsSku4,
				// 		quantity: excelData.quantity4,
				// 	});
				// } else if(excelData.goodsSku5){
				// 	excelData.expressSkuList.push({
				// 		goodsSku: excelData.goodsSku5,
				// 		quantity: excelData.quantity5,
				// 	});
				// }
				
				return excelData;
			// });
		},
		
		//打开导入弹窗
		openExcelDialog() {
			// this.dialogUploadVisible = true;
			this.openTimeUpExc = new Date().getTime();
			this.excelData = [];
			// this.clearFile();
		},


		//查询数据字典
		// getDicData() {
		// 	// let _this = this;
		// 	// console.log(keyword);
		// 	// this.loading_load = true;
		// 	// this.$http
		// 	//   .put(this.$urlConfig.HyDicQueryList, ['wh_packing_type', 'wh_size_unit'])
		// 	//   .then(({ data }) => {
		// 	//     console.log('查询数据字典，请求成功');
		// 	//     console.log(data);
		// 	//     if (200 == data.code && data.data) {
		// 	//       // this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
		// 	//       // this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
		// 	//       // this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
		// 	//       this.selectOption.wh_size_unit = data.data['wh_size_unit'];
		// 	//       this.selectOption.wh_packing_type = data.data['wh_packing_type'];
		// 	//     } else {
		// 	//       if (!data.msg) {
		// 	//         data.msg = this.$t("tips.submitError");
		// 	//       }
		// 	//       this.$message.warning(data.msg);
		// 	//     }
		// 	//   })
		// 	//   .catch(error => {
		// 	//     console.log(error);
		// 	//     console.log('查询数据字典接口，请求失败');
		// 	//     this.$message.error(this.$t("tips.submitRequestError"));
		// 	//   });
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/ .el-input-number,
.el-select {
	// width: 100px;
	.el-input__inner {
		text-align: left;
	}
}
.form_msg {
	color: #e6a23c;
}
/deep/ .el-table__expanded-cell {
	background-color: #f5f5f5;
	padding: 5px 50px;
	// &:hover {
	//   background-color: #f5f5f5;
	// }
	.el-table {
		// tr,th {
		//   background-color: #f5f5f5;
		// }
		th {
			background-color: #d2e2f9;
		}
	}
}

/deep/ .el-table__expand-icon {
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 20px;
	font-weight: bold;
	// padding: 0px;
	// margin-left: -20px;
	// margin-top: -20px;
	.el-icon {
		margin-left: -10px;
		margin-top: -10px;
	}
}
</style>
