import { render, staticRenderFns } from "./transferOrderTem.vue?vue&type=template&id=5cf00f3f&scoped=true&"
import script from "./transferOrderTem.vue?vue&type=script&lang=js&"
export * from "./transferOrderTem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cf00f3f",
  null
  
)

export default component.exports