export const columns_config = () => {
	return [{
			name: "发货编号",
			nameEn:"ShipmentNo",
			data: 'sendNo',
			// editor: 'select',
			// selectOptions: []
			type: 'text',
			// type: 'dropdown',
			// source: []
			// width: 200,
		},
		{
			name: "SKU",
			nameEn:"SKU",
			data: 'goodsSku',
			type: 'text',
			// type: 'dropdown',
			// source: []
		},
		{
			name: "发货件数",
			nameEn:"Shipments quantity",
			data: 'sendAcount',
			type: 'text',
			// type: 'dropdown',
			// source: []
		},
		{
			name: "箱数",
			nameEn:"CTN quantity",
			data: 'sendCTN',
			type: 'text',
		},

		{
			name: "单箱数量",
			nameEn:"CTN PCS",
			data: 'ctnPCS',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "派送方式",
			nameEn:"Method of delivery",
			data: 'carTmsTypeName',
			// type: 'text',
			type: 'dropdown',
			source: []
			// type: 'date', 
			// dateFormat: 'YYYY-MM-DD'
		},
		// {
		// 	name: "外箱长(cm)",
		// 	nameEn:"CTN Length",
		// 	data: 'ctnLength',
		// 	// type: 'numeric',
		// 	type: 'text',
		// },
		// {
		// 	name: "外箱宽(cm)",
		// 	nameEn:"CTN Width",
		// 	data: 'ctnWidth',
		// 	// type: 'numeric',
		// 	type: 'text',
		// },
		// {
		// 	name: "外箱高(cm)",
		// 	nameEn:"CTN Height(cm)",
		// 	data: 'ctnHeight',
		// 	type: 'text',
		// 	// editor: 'select',
		// 	// selectOptions: [],
		// 	// type: 'numeric'
		// 	// type: 'dropdown',
		// 	// source: []
		// },
		// {
		// 	name: "箱毛重(kg)",
		// 	nameEn:"CTN Weight(kg)",
		// 	data: 'ctnWeight',
		// 	// type: 'text'
		// 	// type: 'dropdown',
		// 	// source: []
		// 	type: 'text',
		// },
		{
			name: "FBA仓库代码",
			nameEn:"FBA warehouse code",
			data: 'toWhNo',
			// type: 'numeric',
			type: 'text',
		},

		{
			name: "FBA NO.",
			nameEn:"FBA NO.",
			data: 'fbaNo',
			// type: 'numeric',
			type: 'text',
		},
		{
			name: "发货条码",
			nameEn:"Bar Code",
			data: 'shipmentCode',
			type: 'text',
		},
		{
			name: "箱唛",
			nameEn:"Mark",
			data: 'markNo',
			// type: 'numeric',
			type: 'text',
		},
		// {
		// 	name: "国家",
		// 	nameEn:"Country",
		// 	data: 'country',
		// 	// type: 'numeric',
		// 	type: 'text',
		// },
		// {
		// 	name: "州/省",
		// 	nameEn:"State",
		// 	data: 'state',
		// 	type: 'text',
		// 	// editor: 'select',
		// 	// selectOptions: [],
		// 	// type: 'numeric'
		// 	// type: 'dropdown',
		// 	// source: []
		// },
		// {
		// 	name: "城市",
		// 	nameEn:"City",
		// 	data: 'city',
		// 	// type: 'numeric',
		// 	type: 'text',
		// },
		
		// {
		// 	name: "邮编",
		// 	nameEn:"Postal Code",
		// 	data: 'postalCode',
		// 	// type: 'text'
		// 	// type: 'dropdown',
		// 	// source: []
		// 	type: 'text',
		// },
		// {
		// 	name: "地址",
		// 	nameEn:"Address",
		// 	data: 'addr',
		// 	type: 'text',
		// 	// type: 'date', 
		// 	// dateFormat: 'YYYY-MM-DD'
		// },
		// {
		// 	name: "电话",
		// 	nameEn:"Phone",
		// 	data: 'phone',
		// 	type: 'text',
		// },
		// {
		// 	name: "收件人名称",
		// 	nameEn:"Name",
		// 	data: 'name',
		// 	type: 'text',
		// 	// type: 'dropdown',
		// 	// source: []
		// },
		
		
		// {
		// 	name: "备注",
		// 	nameEn:"Remark",
		// 	data: 'remark',
		// 	// type: 'text'
		// 	type: 'text',
		// },
		{
			name: "ID",
			data: 'id',
			type: 'text',
			readOnly: true
		},
	];
}

export const nestedHeadersTop_config = () => {
	return [
		[
			{ label: '发货信息', colspan: 6 }, 
			{ label: 'FBA', colspan: 2 }, 
			{ label: '条码箱唛', colspan: 2 }, 
			// { label: '收件人信息', colspan: 7 },
			'',
		]
	]
}